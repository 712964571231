import { supabase } from './supabase.api';

const defaultSelect = `
id,
name
`;
const supabaseApi = <T>(tableKey: string, select = defaultSelect) => {
  async function getAll() {
    try {
      const res = await supabase.from<T>(tableKey).select(select);

      if (res.data) {
        return res.data;
      }
      return [];
    } catch (error) {
      console.error(error.message);
      throw error;
    }
  }

  async function upsert<O = T>(newValues) {
    try {
      let { data, error } = await supabase.from<T>(tableKey).upsert(newValues).maybeSingle();

      if (error) {
        throw error;
      }

      if (data) {
        return data;
      }

      throw Error('nope');
    } catch (error) {
      console.error(error.message);
      throw error;
    }
  }

  async function destroy(id: string) {
    try {
      let { error } = await supabase
        .from<any>(tableKey)
        .delete({
          returning: 'minimal',
        })
        .eq('id', id)
        .single();

      if (!error) return true;
      throw Error('nope');
    } catch (error) {
      console.error(error.message);
      throw error;
    }
  }

  return {
    tableKey,
    getAll,
    upsert,
    destroy,
  };
};

export default supabaseApi;
