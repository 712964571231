import { useState, useEffect } from 'react';
import { BaseTrelloEntity } from 'src/modules/recipe-trello/types/_BaseTrelloEntity';
import useSavingState from 'src/modules/common/hooks/useSavingState';
import { HiPlus, HiMinus, HiCheck, HiArrowDown, HiArrowUp } from 'react-icons/hi';
import cloneDeep from 'lodash/cloneDeep';

import sectionsApi from 'src/modules/sections/api/sections.api';
import { useQueryClient, useQuery } from 'react-query';
import { Button } from 'react-bootstrap';
import { Section } from '../types/section';

const sortByOrder = (a, b) => a.order - b.order;

const SecctionsPage = () => {
  const [sections, setSections] = useState<Section[]>([]);
  const [newUnit, setNewUnit] = useState('');
  const { saveState, setSaveState, saveText } = useSavingState();

  const { data, status, error } = useQuery<Section[], any>(sectionsApi.tableKey, () => sectionsApi.getAll());

  const queryClient = useQueryClient();

  // Initialize sections
  useEffect(() => {
    if (data) {
      setSections(cloneDeep(data).sort(sortByOrder));
    }
  }, [data]);

  const update = (idx, updatedVal) => {
    const copy = [...sections];
    copy[idx].name = updatedVal;
    copy[idx]['_markForUpdate'] = true;
    setSections(copy);
  };

  const onSubmit = () => {
    setSaveState('SAVING');

    const itemsToUpdate = sections.filter((item) => item['_markForUpdate']);

    Promise.all(
      itemsToUpdate.map((item) => {
        delete item['_markForUpdate'];
        return sectionsApi.upsert(item);
      })
    ).finally(() => {
      queryClient.setQueryData(sectionsApi.tableKey, sections);
      setSaveState('SAVED');
    });
  };

  const createNew = () => {
    setSaveState('SAVING');

    sectionsApi.upsert({ name: newUnit }).then((newUnit) => {
      setNewUnit('');
      setSections(sections.concat(newUnit));
      setSaveState('SAVED');
      queryClient.setQueryData(sectionsApi.tableKey, sections);
    });
  };

  const destroy = (id: string, sectionIdx: number) => {
    sectionsApi.destroy(id).then(() => {
      const copy = [...sections];
      copy.splice(sectionIdx, 1);
      queryClient.setQueryData(sectionsApi.tableKey, copy);
    });
  };

  const move = (currentIdx: number, replaceIdx: number) => {
    const copy = [...sections];
    const currentOrder = copy[currentIdx].order;
    const replaceOrder = copy[replaceIdx].order;

    copy[currentIdx].order = replaceOrder;
    copy[replaceIdx].order = currentOrder;

    Promise.all(
      [copy[currentIdx], copy[replaceIdx]].map((section) => {
        return sectionsApi.upsert(section);
      })
    ).then(() => {
      copy.sort(sortByOrder);
      queryClient.setQueryData(sectionsApi.tableKey, copy);
    });
  };

  if (status === 'loading') {
    return <span>Loading...</span>;
  }

  if (status === 'error') {
    return <span>Error: {error?.message}</span>;
  }

  const sectionLength = sections.length;

  return (
    <div className="row">
      <div className="col-12">
        <div className="input-group mb-3">
          <input
            className="form-control"
            placeholder="Enter a section..."
            type="text"
            value={newUnit}
            onChange={(e) => setNewUnit(e.target.value)}
          />

          <div className="input-group-append">
            <button className="btn btn-primary" type="button" onClick={() => createNew()}>
              <HiPlus /> Create
            </button>
          </div>
        </div>

        {sections.map((section, sectionIdx) => (
          <div className="input-group mb-3" key={section.id}>
            <input className="form-control" type="text" value={section.name} onChange={(e) => update(sectionIdx, e.target.value)} />
            <div className="input-group-append">
              <Button disabled={section.order === sectionLength} onClick={() => move(sectionIdx, sectionIdx + 1)}>
                <HiArrowDown />
              </Button>
              <Button disabled={section.order === 1} onClick={() => move(sectionIdx, sectionIdx - 1)}>
                <HiArrowUp />
              </Button>
              <button className="btn btn-danger" type="button" onClick={() => destroy(section.id || '', sectionIdx)}>
                <HiMinus /> Delete
              </button>
            </div>
          </div>
        ))}

        <button
          type="button"
          className={saveState === 'SAVED' ? 'btn btn-success' : 'btn btn-primary'}
          onClick={() => onSubmit()}
          disabled={saveState !== 'IDLE'}
        >
          {saveText}
          {saveState === 'SAVED' && <HiCheck />}
        </button>
      </div>
    </div>
  );
};

export default SecctionsPage;
