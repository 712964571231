import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getProfileAsync } from 'src/modules/profile/getProfileThunk';
import { TrelloSettingsInterface } from 'src/modules/common/types/supabase/trello_settings';
import { State } from 'src/modules/common/types/store';
import { BaseTrelloEntity } from 'src/modules/recipe-trello/types/_BaseTrelloEntity';
import { WithTrelloUser } from 'src/modules/recipe-trello/types/_TrelloUser';
import { WithBoards } from 'src/modules/recipe-trello/types/_WithBoards';
import { WithLists } from 'src/modules/recipe-trello/types/_WithLists';
import { getBoardsAsync, getListsAsync, getTrelloProfileAsync } from 'src/modules/recipe-trello/api/trelloThunks';
import { RootState } from 'src/modules/common/app/store';

type IntegrationState = State<TrelloSettingsInterface & WithTrelloUser & WithBoards & WithLists>;
const initialState: IntegrationState = {
  loading: false,
  error: false,
  data: null,
};

export const integrationSlice = createSlice({
  name: 'integration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTrelloProfileAsync.pending, (state) => {
        Object.assign(state, { loading: true, error: false });
      })
      .addCase(getTrelloProfileAsync.rejected, (state) => {
        Object.assign(state, { loading: false, error: true });
      })
      .addCase(getTrelloProfileAsync.fulfilled, (state, action: PayloadAction<BaseTrelloEntity>) => {
        Object.assign<IntegrationState, IntegrationState>(state, {
          loading: false,
          error: false,
          complete: true,
          data: { ...state.data, user: action.payload },
        });
      })
      .addCase(getBoardsAsync.pending, (state) => {
        Object.assign(state, { loading: true, error: false });
      })
      .addCase(getBoardsAsync.rejected, (state) => {
        Object.assign(state, { loading: false, error: true, boards: [] });
      })
      .addCase(getBoardsAsync.fulfilled, (state, action) => {
        Object.assign<IntegrationState, IntegrationState>(state, {
          loading: false,
          error: false,
          data: { ...state.data, boards: action.payload },
        });
      })
      .addCase(getListsAsync.pending, (state) => {
        Object.assign(state, { loading: true, error: false });
      })
      .addCase(getListsAsync.rejected, (state) => {
        Object.assign(state, { loading: false, error: true, boards: [] });
      })
      .addCase(getListsAsync.fulfilled, (state, action) => {
        Object.assign<IntegrationState, IntegrationState>(state, {
          loading: false,
          error: false,
          data: { ...state.data, lists: action.payload },
        });
      })
      .addCase(getProfileAsync.fulfilled, (state, action) => {
        const { integration_settings } = action.payload;
        Object.assign(state, { loading: false, error: false, data: { ...state.data, ...integration_settings } });
      });
  },
});

// export const {} = integrationSlice.actions;

export const selectIntegrationSettings = (state: RootState) => state.integration.data;
export const selectTrelloKeyAndToken = (state: RootState) => ({
  api_key: state.integration.data?.api_key,
  token: state.integration.data?.token,
});
export const selectTrelloUser = (state: RootState) => state.integration.data?.user;
export const selectSelectedLists = (state: RootState) => state.integration.data?.selected_lunch_lists;

export const selectBoards = (state: RootState) => state.integration.data?.boards;
export const selectLists = (state: RootState) => state.integration.data?.lists;

export default integrationSlice.reducer;
