import { supabase } from 'src/modules/common/api/supabase.api';
import useBrowserState from '../../common/hooks/browser-state';
import { useState } from 'react';
import { Button, Col, Form, Row, InputGroup } from 'react-bootstrap';

const LoginPage = () => {
  const { history, from } = useBrowserState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const login = (e) => {
    e.preventDefault();
    supabase.auth.signIn({ email, password }).then(() => {
      history.push('/profile/settings');
    });
  };

  return (
    <Row>
      <Col>
        <Form onSubmit={login}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" required value={email} onChange={(e) => setEmail(e.target.value)} />
            {/* <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text> */}
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" required value={password} onChange={(e) => setPassword(e.target.value)} />
          </Form.Group>

          <Button variant="primary" type="submit" className="mt-3">
            Submit
          </Button>
        </Form>
      </Col>
    </Row>
  );
};

export default LoginPage;
