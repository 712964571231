import { useState } from 'react';
import { useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';

import { selectSelectedLists } from 'src/modules/common/reducers/integrationSlice';
import { getRandomFromArray } from 'src/modules/recipe-trello/utils/card-utils';
import { BaseTrelloEntity } from 'src/modules/recipe-trello/types/_BaseTrelloEntity';
import { useAppDispatch } from 'src/modules/common/app/hooks';
import { setSelectedRecipes } from 'src/modules/recipe-trello/slices/recipePickerSlice';
import useQueryCards from 'src/modules/trello/hooks/useCards';
import { Link } from 'react-router-dom';

import { HiCheck } from 'react-icons/hi';
import { Card, CardColumns, Row, Col, Button } from 'react-bootstrap';
import { CardImage } from './CardImage';
import { isLatestVersion } from 'src/modules/trello/utils/version.utils';

const Step1 = () => {
  const dispatch = useAppDispatch();

  const selectedLists = useSelector(selectSelectedLists) || [];

  const [count, setCount] = useState(5);
  const [recipes, setRecipes] = useState<(BaseTrelloEntity & { selected: boolean })[]>([]);
  const [viewRecipe, setViewRecipe] = useState<BaseTrelloEntity>();

  const allRecipes = useQueryCards(selectedLists).flatMap((q) => q.data);

  const selectRecipesFromList = (count = 1) => {
    return getRandomFromArray(allRecipes, count).map((r) => ({ ...r, selected: true }));
  };

  const updateSelection = (checked: boolean, idx: number) => {
    const _recipes = [...recipes];
    _recipes[idx].selected = checked;
    setRecipes(_recipes);
  };

  const getNewRandomList = () => {
    const _recipes = selectRecipesFromList(count);
    setRecipes(_recipes);
  };

  const appendToList = () => {
    const selectedRecipes = recipes.filter((r) => r.selected);
    const itemsNeeded = count - selectedRecipes.length;

    const _recipes = selectRecipesFromList(itemsNeeded);
    setRecipes(selectedRecipes.concat(_recipes));
  };

  const hasRecipes = !!recipes?.length;

  return (
    <Row>
      <Col>
        <label>How many recipes do you need? </label>
        <div className="input-group">
          <input className="form-control" type="number" value={count} onChange={(e) => setCount(Number(e.target.value))} />

          <div className="input-group-append">
            <button type="button" className="btn btn-primary" onClick={() => getNewRandomList()}>
              Generate {count} random recipes
            </button>
          </div>
        </div>

        {hasRecipes && (
          <CardColumns className="mt-5">
            {recipes.map((recipe, recipeIdx) => (
              <Card key={recipe.id}>
                <CardImage images={recipe.cover?.scaled} />

                <Card.Body>
                  <Card.Title>{recipe.name}</Card.Title>
                  <Card.Text>
                    <Link to={`/trello/update/${recipe.id}`}>EDIT</Link>
                  </Card.Text>
                  {isLatestVersion({ trelloCard: recipe }) && (
                    <Card.Text>
                      <small className="text-muted">
                        Updated <HiCheck size="24" color="green" />
                      </small>
                    </Card.Text>
                  )}
                </Card.Body>
              </Card>
            ))}
            {/* <Card className="p-3">
            <blockquote className="blockquote mb-0 card-body">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
              <footer className="blockquote-footer">
                <small className="text-muted">
                  Someone famous in <cite title="Source Title">Source Title</cite>
                </small>
              </footer>
            </blockquote>
          </Card>
          <Card>
            <Card.Img variant="top" src="holder.js/100px160" />
            <Card.Body>
              <Card.Title>Card title</Card.Title>
              <Card.Text>This card has supporting text below as a natural lead-in to additional content. </Card.Text>
            </Card.Body>
            <Card.Footer>
              <small className="text-muted">Last updated 3 mins ago</small>
            </Card.Footer>
          </Card>
          <Card bg="primary" text="white" className="text-center p-3">
            <blockquote className="blockquote mb-0 card-body">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
              <footer className="blockquote-footer">
                <small className="text-muted">
                  Someone famous in <cite title="Source Title">Source Title</cite>
                </small>
              </footer>
            </blockquote>
          </Card>
          <Card className="text-center">
            <Card.Body>
              <Card.Title>Card title</Card.Title>
              <Card.Text>This card has supporting text below as a natural lead-in to additional content. </Card.Text>
              <Card.Text>
                <small className="text-muted">Last updated 3 mins ago</small>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img src="holder.js/100px160" />
          </Card>
          <Card className="text-right">
            <blockquote className="blockquote mb-0 card-body">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
              <footer className="blockquote-footer">
                <small className="text-muted">
                  Someone famous in <cite title="Source Title">Source Title</cite>
                </small>
              </footer>
            </blockquote>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>Card title</Card.Title>
              <Card.Text>
                This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer
                content than the first to show that equal height action.
              </Card.Text>
              <Card.Text>
                <small className="text-muted">Last updated 3 mins ago</small>
              </Card.Text>
            </Card.Body>
          </Card> */}
          </CardColumns>
        )}
      </Col>
    </Row>
  );

  // return (
  //   <div>
  //     <div className="form-group">
  //       <label>
  //         How many recipes do you need?
  //         <input className="form-control" type="number" value={count} onChange={(e) => setCount(Number(e.target.value))} />
  //       </label>
  //     </div>
  //     <button type="button" className="btn btn-primary" onClick={() => getNewRandomList()}>
  //       Select {count} random recipes
  //     </button>
  //     {!!recipes?.length && (
  //       <table className="table">
  //         <thead>
  //           <tr>
  //             <th scope="col">Selected</th>
  //             <th scope="col">Name</th>
  //             <th scope="col">Labels</th>
  //             <th scope="col">Updated</th>
  //             <th scope="col">Edit</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {recipes.map((recipe, recipeIdx) => (
  //             <tr key={recipeIdx}>
  //               {/* Selected */}
  //               <td>
  //                 <input
  //                   type="checkbox"
  //                   className="form-check-input"
  //                   checked={recipe.selected}
  //                   onChange={(e) => updateSelection(e.target.checked, recipeIdx)}
  //                 />
  //               </td>
  //               {/* Name */}
  //               <td role="button" onClick={() => setViewRecipe(recipe)}>
  //                 {recipe.name}
  //               </td>
  //               {/* Label? */}
  //               <td>
  //                 {recipe.labels?.map((label, labelIdx) => (
  //                   <small key={labelIdx}>
  //                     <span className="badge" style={{ backgroundColor: label.color === 'sky' ? 'skyblue' : label.color }}>
  //                       {label.name}
  //                     </span>
  //                   </small>
  //                 ))}
  //               </td>
  //               {/* Updated */}
  //               <td>{recipe.customFieldItems && recipe.customFieldItems[0]?.value?.number && <HiCheck size="24" />}</td>
  //               <td>
  //                 <Link to={`/trello/update/${recipe.id}`}>EDIT</Link>
  //               </td>
  //             </tr>
  //           ))}
  //         </tbody>
  //       </table>
  //     )}

  //     {recipes.length > 0 && (
  //       <>
  //         <hr />
  //         <button type="button" className="btn btn-success mr-2" onClick={(e) => dispatch(setSelectedRecipes(recipes))}>
  //           Accept selection?
  //         </button>
  //         <button type="button" className="btn btn-info" onClick={(e) => appendToList()}>
  //           Replace unchecked
  //         </button>
  //         <hr />
  //         {viewRecipe && <ReactMarkdown>{viewRecipe.desc || ''}</ReactMarkdown>}
  //       </>
  //     )}
  //   </div>
  // );
};

export default Step1;
