import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { recipesApi } from '../api/recipe.api';
import { Recipe } from '../types/recipe';
import { trelloApi } from '../api/trelloApi';

export const useAutoCreateTrelloRecipe = (id) => {
  const queryClient = useQueryClient();

  const cardQuery = useQuery(['trello-card', id.toString()], () => trelloApi.getCardById(id));
  const recipeQuery = useQuery(['recipe', id], () => recipesApi.getByTrelloId(id));

  const [recipe, setRecipe] = useState<Recipe | null>(null);

  useEffect(() => {
    if (cardQuery.status === 'success' && recipeQuery.status === 'success') {
      if (recipeQuery.data) {
        setRecipe(recipeQuery.data);
      } else {
        recipesApi
          .upsert({
            trello_id: id,
            name: cardQuery.data.name,
            description: cardQuery.data.desc,
          })
          .then((recipe) => {
            queryClient.setQueryData(['recipe', id], recipe);
            setRecipe(recipe);
          });
      }
    }
  }, [cardQuery, recipeQuery]);

  return recipeQuery;
};

export default useAutoCreateTrelloRecipe;
