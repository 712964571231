import { useState, useEffect } from 'react';
type SaveState = 'IDLE' | 'SAVED' | 'SAVING';
const useSavingState = () => {
  const [saveState, setSaveState] = useState<SaveState>('IDLE');

  const textMap: { [key in SaveState]: string } = {
    IDLE: 'Save',
    SAVED: 'Saved',
    SAVING: 'Saving',
  };

  useEffect(() => {
    let ref;
    if (saveState === 'SAVED') {
      ref = setTimeout(() => setSaveState('IDLE'), 2000);
    }
    return () => clearTimeout(ref);
  }, [saveState]);

  return {
    saveState,
    setSaveState,
    saveText: textMap[saveState],
  };
};

export default useSavingState;
