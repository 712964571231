import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Session } from '@supabase/supabase-js';
import { RootState } from '../app/store';
import { getProfileAsync } from 'src/modules/profile/getProfileThunk';
import { State } from 'src/modules/common/types/store';
import { WithSession } from 'src/modules/common/types/supabase/_session';
import { ProfileInterface } from 'src/modules/common/types/supabase/profile';
import { getTrelloProfileAsync } from '../../recipe-trello/api/trelloThunks';

type AuthState = State<{ user?: ProfileInterface } & WithSession>;

const initialState: AuthState = {
  loading: true,
  error: false,
  data: null,
  complete: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<Session>) => {
      state.data = { ...state.data, session: action.payload };
    },
    loadFinished: (state) => {
      state.loading = false;
      state.complete = true;
    },
    logout: (state) => {
      state.loading = false;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileAsync.pending, (state) => {
        Object.assign(state, { loading: true, error: false });
      })
      .addCase(getProfileAsync.rejected, (state) => {
        Object.assign<AuthState, Partial<AuthState>>(state, { loading: false, error: true });
      })
      .addCase(getProfileAsync.fulfilled, (state, action) => {
        const { user, session } = action.payload;
        Object.assign<AuthState, AuthState>(state, { loading: false, error: false, data: { user, session } });
      })
      .addCase(getTrelloProfileAsync.fulfilled, (state, action) => {
        Object.assign<AuthState, AuthState>(state, { ...state, loading: false, error: false, complete: true });
      });
  },
});

export const { logout, loadFinished } = authSlice.actions;

export const selectAuthState = (state: RootState) => state.auth;
export const selectUser = (state: RootState) => state.auth.data?.user;
export const selectSession = (state: RootState) => state.auth.data?.session;

export default authSlice.reducer;
