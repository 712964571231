import { Row, Col, Form } from 'react-bootstrap';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import ProgressButton from 'src/modules/common/components/button/ProgressButton';
import { recipesApi } from '../../api/recipe.api';
import { useEffect, useState } from 'react';
import { trelloApi } from '../../api/trelloApi';
import { getLatestVersion, latestVersion, versionFromTrello } from '../../utils/version.utils';

export const UpdateRefresh = () => {
  let { trelloId } = useParams<any>();

  const recipeQuery = useQuery(['recipe', trelloId], () => recipesApi.getByTrelloId(trelloId));
  const cardQuery = useQuery(['trello-card', trelloId.toString()], () => trelloApi.getCardById(trelloId));
  const queryClient = useQueryClient();

  const [allValid, setAllValid] = useState(false);
  const [version, setVersion] = useState('');

  useEffect(() => {
    const ingredients = recipeQuery.data?.ingredients;
    if (ingredients) {
      const allValid = ingredients.every((ingredient) => !!ingredient.unit && !!ingredient.item);
      setAllValid(allValid);
    }
  }, [recipeQuery.data?.ingredients]);

  useEffect(() => {
    const currentVersion = getLatestVersion(versionFromTrello(cardQuery.data), recipeQuery.data?.version);
    setVersion(currentVersion.toString());
  }, [cardQuery.data?.customFieldItems, recipeQuery.data]);

  if (recipeQuery.status !== 'success' || cardQuery.status !== 'success') return <div>loading...</div>;

  if (!cardQuery) return <div>Can't find that trello card</div>;

  const onSubmit = (e, complete) => {
    e.preventDefault();
    if (!cardQuery.data) return;
    const trelloCard = cardQuery.data;
    const [customField] = trelloCard.customFieldItems || [];

    // Update version on Trello
    trelloApi.updateCustomFieldOnCard(trelloId, '5f85d9da8bf20354f3f8e5e4', {
      value: { number: !!version ? version : latestVersion },
    });

    // Update Recipe with latest {name, description, version}
    if (recipeQuery.data) {
      const recipe = { ...recipeQuery.data };
      if (!recipe.description) recipe.description = trelloCard.desc;

      const { name, description, id } = recipe;
      recipesApi.upsert({ id, name, description, version, trello_id: trelloCard.id }).then((updatedRecipe) => {
        queryClient.setQueryData(['recipe', trelloId], { ...recipe, version: updatedRecipe.version });
        complete();
      });
    }
  };

  return (
    <Row className="mt-4">
      <Col xs={12} md={6}>
        <Form.Group controlId="version">
          <Form.Label>Current version</Form.Label>
          <Form.Control
            type="text"
            disabled={!version}
            value={!!version ? version : latestVersion}
            onChange={(e) => setVersion(e.target.value)}
          />
          <Form.Text className="text-muted">Latest version: {latestVersion}</Form.Text>
        </Form.Group>

        <Form.Group className="mt-3">
          <ProgressButton disabled={!allValid} onClick={(e, complete) => onSubmit(e, complete)}></ProgressButton>
        </Form.Group>
      </Col>
    </Row>
  );
};
