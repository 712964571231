import { useState, useEffect } from 'react';
import useSavingState from 'src/modules/common/hooks/useSavingState';

import { HiCheck, HiPlus } from 'react-icons/hi';
import ProgressButton from 'src/modules/common/components/button/ProgressButton';
import { itemsApi } from '../api/items.api';
import { Item as ItemType } from 'src/modules/trello/types/item';
import { Item } from './Item';
import { useQuery } from 'react-query';
import { Section } from 'src/modules/sections/types/section';

import sectionsApi from 'src/modules/sections/api/sections.api';

export const ItemsPage = () => {
  const [items, setItems] = useState<ItemType[]>([]);
  const [newItem, setNewItem] = useState('');
  const { saveState, setSaveState, saveText } = useSavingState();

  const { data, status } = useQuery<Section[], any>(sectionsApi.tableKey, () => sectionsApi.getAll());

  useEffect(() => {
    itemsApi.getAll().then((d) => setItems(d));
  }, []);

  const update = (idx, updatedItem: ItemType) => {
    const copy = [...items];
    copy[idx] = updatedItem;
    copy[idx]['_markForUpdate'] = true;

    setItems(copy);
  };

  const onSubmit = (e, complete) => {
    setSaveState('SAVING');

    const itemsToUpdate = items.filter((item) => item['_markForUpdate']);

    Promise.all(
      itemsToUpdate.map((item) => {
        delete item['_markForUpdate'];
        return itemsApi.upsert(item);
      })
    ).finally(() => {
      setSaveState('SAVED');
      complete();
    });
  };

  const createNew = () => {
    setSaveState('SAVING');

    itemsApi.upsert({ name: newItem }).then((_newItem) => {
      setNewItem('');
      setItems(items.concat(_newItem));
      setSaveState('SAVED');
    });
  };

  const destroy = (id: string, itemsIdx: number) => {
    itemsApi.destroy(id).then(() => {
      const copy = [...items];
      copy.splice(itemsIdx, 1);
      setItems(copy);
    });
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="input-group mb-3">
          <input
            className="form-control"
            type="text"
            placeholder="Enter a unit..."
            value={newItem}
            onChange={(e) => setNewItem(e.target.value)}
          />

          <div className="input-group-append">
            <button className="btn btn-primary" type="button" onClick={() => createNew()}>
              Create <HiPlus />
            </button>
          </div>
        </div>

        {items.map((item, itemIdx) => (
          <Item key={itemIdx} itemIdx={itemIdx} item={item} onDestroy={destroy} onUpdate={update} sections={data} />
        ))}

        <ProgressButton onClick={onSubmit}></ProgressButton>
      </div>
    </div>
  );
};
