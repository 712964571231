import { useParams } from 'react-router-dom';
import { Tabs, Tab, Col, Row } from 'react-bootstrap';
import UpdateInstructions from './UpdateInstructions';
import UpdateIngredients from './UpdateIngredients';
import useAutoCreateTrelloRecipe from '../../hooks/useAutoCreateTrelloRecipe';
import { UpdateRefresh } from './UpdateRefresh';
import { isLatestVersion } from '../../utils/version.utils';
import { HiCheck } from 'react-icons/hi';

const UpdateTrelloPage = () => {
  let { trelloId } = useParams<any>();

  const recipeQuery = useAutoCreateTrelloRecipe(trelloId);

  if (recipeQuery.status === 'loading') {
    return <span>Loading...</span>;
  }

  if (!recipeQuery.data) {
    return <div>Can't find recipe at Trello</div>;
  }

  return (
    <Row>
      <Col xs="12">
        <h2>
          {recipeQuery.data.name} {isLatestVersion({ recipe: recipeQuery.data }) && <HiCheck color="green" />}
        </h2>

        <Tabs defaultActiveKey="Instructions">
          <Tab eventKey="Instructions" title="Instructions">
            <UpdateInstructions />
          </Tab>
          <Tab eventKey="Ingredients" title="Shopping list">
            <UpdateIngredients />
          </Tab>
          <Tab eventKey="Update" title="Update">
            <UpdateRefresh />
          </Tab>
        </Tabs>
      </Col>
    </Row>
  );
};

export default UpdateTrelloPage;
