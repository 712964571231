import { useState, useEffect } from 'react';
import { HiMinus, HiChevronUp, HiChevronDown, HiSave, HiCheck } from 'react-icons/hi';
import { Unit as UnitType } from 'src/modules/trello/types/unit';
import unitsApi from '../api/units.api';
import ProgressButton from '../../common/components/button/ProgressButton';
import { Spinner } from '../../common/components/button/ProgressButton';

type Props = {
  unit: UnitType;
  unitIdx: number;
  onDestroy: (id: string, idx: number) => void;
  onUpdate: (idx: number, unit: UnitType) => void;
};

export const Unit = ({ unit, unitIdx, onDestroy, onUpdate }: Props) => {
  const [currentUnit, setCurrentUnit] = useState(unit);
  const [show, setShow] = useState(false);
  const [synonyms, setSynonyms] = useState(unit.synonyms || []);

  useEffect(() => {
    onUpdate(unitIdx, {
      ...currentUnit,
      synonyms,
    });
  }, [currentUnit, synonyms]);

  const save = (e, complete) => {
    unitsApi
      .upsert({
        ...currentUnit,
        synonyms,
      })
      .then((updatedUnit) => {
        complete();
      });
  };

  return (
    <>
      <div className="input-group mb-3" key={currentUnit.id}>
        <input
          className="form-control"
          type="text"
          value={currentUnit.name}
          onChange={(e) => setCurrentUnit({ ...currentUnit, name: e.target.value })}
        />

        <div className="input-group-append">
          <button className="btn btn-info" type="button" onClick={() => setShow(!show)}>
            {show ? <HiChevronUp /> : <HiChevronDown />} <span className="d-none d-md-inline">Synonyms</span>
          </button>
          <button className="btn btn-danger" type="button" disabled={!unit.id} onClick={() => onDestroy(unit.id || '', unitIdx)}>
            <HiMinus /> <span className="d-none d-md-inline">Delete</span>
          </button>

          <ProgressButton onClick={save} disabled={!unit.id} icons={[HiSave, Spinner, HiCheck]} hideTextOnMobile></ProgressButton>
        </div>
      </div>

      {show && (
        <div className="form-group mb-4">
          <label htmlFor="exampleFormControlTextarea1">Symonym-list</label>
          <textarea
            className="form-control"
            id="exampleFormControlTextarea1"
            rows={3}
            value={synonyms.join(',')}
            onChange={(e) => setSynonyms(e.target.value.split(','))}
          ></textarea>
        </div>
      )}
    </>
  );
};
