import { useSelector } from 'react-redux';
import { selectUser } from 'src/modules/common/reducers/authSlice';
import { selectTrelloKeyAndToken } from 'src/modules/common/reducers/integrationSlice';
import { AuthGuard } from 'src/modules/common/types/guard';

export const useLoginGuard = () => {
  const user = useSelector(selectUser);
  return { guardKey: AuthGuard.LOGIN, value: !!user };
};

export const useTrelloTokenGuard = () => {
  const { api_key, token } = useSelector(selectTrelloKeyAndToken);

  return { guardKey: AuthGuard.TRELLO, value: !!api_key && !!token };
};
