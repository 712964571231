import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { supabase } from 'src/modules/common/api/supabase.api';
import { selectUser } from 'src/modules/common/reducers/authSlice';

// import useBrowserState from '../../hooks/browser-state';
import { Button } from 'react-bootstrap';

const AuthButton = () => {
  // const { history, from } = useBrowserState();
  const user = useSelector(selectUser);

  const signout = (e) => {
    e.preventDefault();
    supabase.auth.signOut();
    // auth.signout(() => history.push('/'));
  };

  return !!user ? (
    <>
      Signed in as <Link to="/profile">{user.username}</Link>{' '}
      <a href="/" className="navbar-link" onClick={signout}>
        Sign out
      </a>
    </>
  ) : (
    <Link to="/login">Login</Link>
  );
};

export default AuthButton;
