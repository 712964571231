export function getRandomFromArray(lists: any[], count = 5) {
  // Shuffle array
  const shuffled = lists.sort((a, b) => 0.5 - Math.random() - getAmplifier(a));

  // Get sub-array of first n elements after shuffled
  return shuffled.slice(0, count);
}

function getAmplifier(card) {
  if (!card.customFieldItems || card.customFieldItems.length === 0) return 0;

  const count = Number(card.customFieldItems[0].value.number);

  return count / 100;
}
