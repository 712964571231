import { createAsyncThunk } from '@reduxjs/toolkit';
import { Session } from '@supabase/supabase-js';
import pick from 'lodash/pick';
import { ProfileInterface, WithProfileForeignKeys } from 'src/modules/common/types/supabase/profile';
import { supabase } from 'src/modules/common/api/supabase.api';

export const getProfileAsync = createAsyncThunk('auth/fetchingProfile', async (session: Session) => {
  return getProfile(session)
    .then((response) => {
      const user = pick(response, ['username', 'id']);
      return { user, session, integration_settings: response.trello_settings };
    })
    .catch((err) => {
      throw err;
    });
});

const getProfile = async (session: Session) => {
  try {
    const { data, error, status } = await supabase
      .from<ProfileInterface & WithProfileForeignKeys>('profiles')
      .select(
        `username, id, trello_settings(
        api_key,
        token,
        selected_board,
        selected_lunch_lists
      )`
      )
      .eq('id', session.user?.id)
      .single();

    if (!data || (error && status !== 406)) {
      throw error;
    }

    return data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};
