import { useQueries } from 'react-query';
import { getRandomFromArray } from 'src/modules/recipe-trello/utils/card-utils';
import { trelloApi } from 'src/modules/trello/api/trelloApi';

const useQueryCards = (selectedLists: string[], count = 100) => {
  return useQueries(
    selectedLists.map((listId) => {
      return {
        queryKey: ['listId', listId],
        queryFn: async () => {
          const cards = await trelloApi.getCardsOnList(listId);
          return getRandomFromArray(cards, count);
        },
      };
    })
  );
};

export default useQueryCards;
