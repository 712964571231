import { BaseTrelloEntity } from 'src/modules/recipe-trello/types/_BaseTrelloEntity';

var myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');

var requestOptions = {
  headers: myHeaders,
};

// const fakeApi = new Promise((resolve) => {
//   setTimeout(() => resolve(true), 1000);
// });

export class Trello {
  public static instance;
  public getOauthUrl = (appName = 'SallaMolly', returnUrl = 'http://localhost:3000/profile/trello-integration') =>
    `https://trello.com/1/authorize?expiration=never&name=${appName}&scope=read,write&key=${this.apiKey}&callback_method=token&return_url=${returnUrl}`;

  public BASE = 'https://api.trello.com/1/';
  public apiKey;
  public token;

  // constructor() {
  //   // your logic here
  // }
  public static getInstance(): Trello {
    if (Trello.instance) {
      return Trello.instance;
    }
    Trello.instance = new Trello();
    return Trello.instance;
  }

  public setApiKey(apiKey) {
    this.apiKey = apiKey;
  }

  public setToken(token) {
    this.token = token;
  }

  me(): Promise<BaseTrelloEntity> {
    return fetch(`${this.BASE}members/me?key=${this.apiKey}&token=${this.token}`).then((res) => res.json());
  }

  getBoards(userId: string): Promise<BaseTrelloEntity[]> {
    return fetch(`${this.BASE}members/${userId}/boards?key=${this.apiKey}&token=${this.token}`).then((res) => res.json());
  }

  getCardById(cardId: string): Promise<BaseTrelloEntity> {
    return fetch(`${this.BASE}cards/${cardId}?key=${this.apiKey}&token=${this.token}&customFieldItems=true&attachments=true`).then((res) =>
      res.json()
    );
  }

  updateCard(card: BaseTrelloEntity): Promise<BaseTrelloEntity> {
    return fetch(`${this.BASE}cards/${card.id}?key=${this.apiKey}&token=${this.token}`, {
      ...requestOptions,
      method: 'PUT',
      body: JSON.stringify(card),
    }).then((res) => res.json());
  }

  updateCustomFieldOnCard(cardId: string, idCustomField: string, value: object): Promise<BaseTrelloEntity> {
    return fetch(`${this.BASE}cards/${cardId}/customField/${idCustomField}/item?key=${this.apiKey}&token=${this.token}`, {
      ...requestOptions,
      method: 'PUT',
      body: JSON.stringify(value),
    }).then((res) => res.json());
  }

  getChecklists(cardId, queryClient): Promise<BaseTrelloEntity> {
    return fetch(`${this.BASE}cards/${cardId}/checklists?key=${this.apiKey}&token=${this.token}&customFieldItems=true&attachments=true`)
      .then((res) => res.json())
      .then((checkLists) => {
        checkLists[0].checkItems.forEach((item) => {
          queryClient.setQueryData(['trello-card-checkItem', item.id.toString()], item);
        });

        return checkLists[0];
      });
  }
  updateCheckItem(cardId: string, checkItem: BaseTrelloEntity): Promise<BaseTrelloEntity> {
    return fetch(`${this.BASE}cards/${cardId}/checkItem/${checkItem.id}?key=${this.apiKey}&token=${this.token}`, {
      ...requestOptions,
      method: 'PUT',
      body: JSON.stringify(checkItem),
    }).then((res) => res.json());
  }

  deleteCheckItem(cardId: string, checkItemId: string): Promise<any> {
    return fetch(`${this.BASE}cards/${cardId}/checkItem/${checkItemId}?key=${this.apiKey}&token=${this.token}`, {
      ...requestOptions,
      method: 'DELETE',
    });
  }

  getListsOnBoard(boardId): Promise<BaseTrelloEntity[]> {
    return fetch(`${this.BASE}boards/${boardId}/lists?key=${this.apiKey}&token=${this.token}`).then((res) => res.json());
  }

  getCardsOnList(listId): Promise<BaseTrelloEntity[]> {
    return fetch(`${this.BASE}lists/${listId}/cards?key=${this.apiKey}&token=${this.token}&customFieldItems=true&attachments=true`).then(
      (res) => res.json()
    );
  }

  getChecklistById(checklistId) {
    return fetch(`${this.BASE}checklists/${checklistId}?key=${this.apiKey}&token=${this.token}`).then((res) => res.json());
  }
}

export const trelloApi = Trello.getInstance();
