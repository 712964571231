import { useState, useEffect, useCallback, useRef } from 'react';

import unitsApi from 'src/modules/units/api/units.api';
import { ingredientsApi, itemsApi } from '../../api/recipe.api';

import { useQuery } from 'react-query';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/esm/Button';
import debounce from 'lodash/debounce';
import { Ingredient, UpsertIngredient } from '../../types/ingredient';
import { Item } from '../../types/item';
import { Unit } from '../../types/unit';

import { useQueryClient } from 'react-query';
import { CustomMenu, CustomToggle } from 'src/modules/common/components/dropdown/CustomDropdown';
import { matchNameOrSynonyms } from 'src/modules/common/utils/matchers';
import { Col, Row, InputGroup } from 'react-bootstrap';
import ProgressButton from '../../../common/components/button/ProgressButton';

type GuessType = {
  item?: string;
  unit?: string;
  quantity?: string;
};

type Props = {
  ingredient: Ingredient;
  onSave?: (updated: Ingredient) => void;
  onDestroy?: () => void;
};

const ParseIngredient = ({ ingredient, onSave, onDestroy }: Props) => {
  const { id, recipe_id } = ingredient;

  const [item, setItem] = useState<Item | undefined>(undefined);
  const [quantity, setQuantity] = useState('');
  const [unit, setUnit] = useState<Unit | undefined>(undefined);
  const [guess, setGuess] = useState<GuessType>({ item: '', unit: '' });
  const [ingredientName, setIngredientName] = useState('');

  const { data: allUnits } = useQuery(unitsApi.tableKey, () => unitsApi.getAll());
  const { data: allItems } = useQuery(itemsApi.tableKey, () => itemsApi.getAll());

  const queryClient = useQueryClient();

  const selectUnitRef = useRef<any>(null);
  const selectItemRef = useRef<any>(null);

  useEffect(() => {
    function parser(str: string) {
      const parts = str.split(',').map((p) => p.trim());
      const [itemPart, quantityPart, unitPart] = parts;

      if (!item && itemPart) {
        const foundItem = allItems?.find(matchNameOrSynonyms(itemPart));
        foundItem && setItem(foundItem);
      }

      if (quantityPart) {
        setQuantity(quantityPart);
      }

      if (!unit && unitPart) {
        const foundUnit = allUnits?.find(matchNameOrSynonyms(unitPart));
        foundUnit && setUnit(foundUnit);
      }

      return {
        itemPart,
        unitPart,
      };
    }

    if (allItems && allUnits && ingredientName) {
      const { itemPart, unitPart } = parser(ingredientName);

      setGuess({
        item: itemPart,
        unit: unitPart,
      });
    }
  }, [allUnits, allItems, ingredientName]);

  useEffect(() => {
    if (ingredient.item) setItem(ingredient.item);
    if (ingredient.unit) setUnit(ingredient.unit);
    if (ingredient.quantity) setQuantity(ingredient.quantity);
    if (ingredient.name) setIngredientName(ingredient.name);
  }, [ingredient]);

  // useEffect(() => {
  //   update({ unit, quantity, ingredient });
  // }, [unit, quantity, ingredient]);

  // const update = useCallback(
  //   debounce((data) => {
  //     console.log(data);
  //   }, 600),
  //   []
  // );

  const createItem = (itemName) => {
    itemsApi.upsert({ name: itemName }).then((newItem) => {
      setItem(newItem);
      queryClient.setQueryData(itemsApi.tableKey, [newItem, ...(allItems as Item[])]);
      selectItemRef?.current?.click();
    });
  };

  const createUnit = (itemName) => {
    unitsApi.upsert({ name: itemName }).then((newUnit) => {
      setUnit(newUnit);
      queryClient.setQueryData(unitsApi.tableKey, [newUnit, ...(allUnits as Item[])]);
      selectUnitRef?.current?.click();
    });
  };

  const save = (complete) => {
    ingredientsApi
      .upsert<UpsertIngredient>({ id, recipe_id, quantity, name: ingredientName, unit: unit?.id, item: item?.id })
      .then((updatedIngredient) => {
        onSave && onSave({ ...updatedIngredient, item, unit });
        complete();
      });
  };

  const destroy = () => {
    id &&
      ingredientsApi.destroy(id).then((deleted) => {
        onDestroy && onDestroy();
      });
  };

  if (status === 'loading') {
    return <span>Loading...</span>;
  }

  const variant = (entity) => (entity ? 'outline-success' : 'outline-warning');

  return (
    <Row>
      <Col xs="12" md="6">
        <InputGroup className="mb-3">
          <input className="form-control" type="text" value={ingredientName} onChange={(e) => setIngredientName(e.target.value)} />
        </InputGroup>
      </Col>

      <Col xs="12" md="6">
        <InputGroup className="mb-3">
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} variant={variant(item)} ref={selectItemRef}>
              {item?.name || 'Select Item...'}
            </Dropdown.Toggle>

            <Dropdown.Menu as={CustomMenu} onCreate={(itemName) => createItem(itemName)} guess={item?.name || guess.item} align="end">
              {allItems?.map((_item, unitIdx) => (
                <Dropdown.Item
                  key={unitIdx}
                  active={_item === item}
                  eventKey={_item.id}
                  onClick={(e: any) => setItem(_item)}
                  synonyms={_item.synonyms}
                >
                  {_item.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </InputGroup>
      </Col>

      <Col xs="12" md="6">
        <div className="input-group mb-3">
          <input className="form-control" type="text" value={quantity || '1'} onChange={(e) => setQuantity(e.target.value)} />
        </div>
      </Col>

      <Col xs="12" md="6">
        <InputGroup className="mb-3">
          <Dropdown className="mb-3">
            <Dropdown.Toggle as={CustomToggle} variant={variant(unit)} ref={selectUnitRef}>
              {unit?.name || 'Select Unit...'}
            </Dropdown.Toggle>

            <Dropdown.Menu as={CustomMenu} onCreate={(itemName) => createUnit(itemName)} guess={unit?.name || guess.unit} align="end">
              {allUnits?.map((_unit, unitIdx) => (
                <Dropdown.Item
                  key={unitIdx}
                  active={_unit === unit}
                  synonyms={_unit.synonyms}
                  eventKey={_unit.id}
                  onClick={(e: any) => setUnit(_unit)}
                >
                  {_unit.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </InputGroup>
      </Col>

      <Col xs="12" md="6">
        <InputGroup className="mb-3">
          <ProgressButton className="w-100" type="button" onClick={(e, complete) => save(complete)}></ProgressButton>
        </InputGroup>
      </Col>
      <Col xs="12" md="6">
        <InputGroup className="mb-3">
          <Button className="w-100" variant="danger" onClick={() => destroy()}>
            Delete
          </Button>
        </InputGroup>
      </Col>
    </Row>
  );
};

export default ParseIngredient;
