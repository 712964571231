import { useEffect } from 'react';
import { useAppDispatch } from 'src/modules/common/app/hooks';
import { supabase } from 'src/modules/common/api/supabase.api';
import { getProfileAsync } from 'src/modules/profile/getProfileThunk';
import { loadFinished, logout } from 'src/modules/common/reducers/authSlice';

const useAuthStateChange = () => {
  const dispatch = useAppDispatch();
  const session = supabase.auth.session();

  useEffect(() => {
    if (!session) {
      dispatch(loadFinished());
    }

    const sub = supabase.auth.onAuthStateChange((_event, session) => {
      if (session) {
        dispatch(getProfileAsync(session));
      } else {
        dispatch(logout());
      }
    });

    return sub.data?.unsubscribe;
  }, [dispatch, session]);
};

export default useAuthStateChange;
