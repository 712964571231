import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/modules/common/app/store';
import { DefinedState } from 'src/modules/common/types/store';

type recipePickerState = {
  step: number;
  selectedRecipes: { idChecklists: string[] }[];
};

const initialState: DefinedState<recipePickerState> = {
  loading: false,
  error: false,
  data: {
    step: 1,
    selectedRecipes: [],
  },
};

export const recipePickerSlice = createSlice({
  name: 'recipePicker',
  initialState,
  reducers: {
    reset: (state) => {
      Object.assign(state, initialState);
    },
    setSelectedRecipes: (state, action) => {
      Object.assign(state, {
        loading: false,
        error: false,
        data: { ...state.data, step: 2, selectedRecipes: action.payload },
      });
    },
  },
});

export const { setSelectedRecipes, reset } = recipePickerSlice.actions;

export const selectStep = (state: RootState) => state.recipePicker.data.step;
export const selectSelectedRecipes = (state: RootState) => state.recipePicker.data.selectedRecipes;

export default recipePickerSlice.reducer;
