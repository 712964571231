import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch, useRouteMatch, Route, Link, useLocation } from 'react-router-dom';

// Common
import { selectBoards, selectIntegrationSettings, selectLists, selectTrelloUser } from 'src/modules/common/reducers/integrationSlice';
import { useAppDispatch } from 'src/modules/common/app/hooks';
import { selectSession, selectUser } from 'src/modules/common/reducers/authSlice';
import { TrelloSettingsInterface } from 'src/modules/common/types/supabase/trello_settings';
import { updateIntegration, updateProfile } from 'src/modules/common/api/supabase.api';

// Cross
import { getBoardsAsync, getListsAsync } from 'src/modules/recipe-trello/api/trelloThunks';
import { trelloApi } from 'src/modules/trello/api/trelloApi';

const ProfilePage = () => {
  let { url } = useRouteMatch();
  const { pathname } = useLocation();

  const urls = {
    trelloIntegration: `${url}/trello-integration`,
    settings: `${url}/settings`,
  };

  return (
    <>
      <ul className="nav nav-tabs mb-4">
        <li className="nav-item">
          <Link className={pathname === urls.settings ? 'nav-link active' : 'nav-link'} to={urls.settings}>
            Profile Settings
          </Link>
        </li>

        <li className="nav-item">
          <Link className={pathname === urls.trelloIntegration ? 'nav-link active' : 'nav-link'} to={urls.trelloIntegration}>
            Trello Integration
          </Link>
        </li>
      </ul>

      <Switch>
        <Route path={`${url}/settings`}>
          <Settings />
        </Route>
        <Route path={`${url}/trello-integration`}>
          <TrelloIntegration />
        </Route>
      </Switch>
    </>
  );
};

const Settings = () => {
  const user = useSelector(selectUser);
  const session = useSelector(selectSession);
  if (!user) throw Error('Missing User');

  const [username, setUsername] = useState(user.username || '');
  const [password, setPassword] = useState('');

  const [saved, setSaved] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    const result = await updateProfile(session, username, password);
    setSaved(result);
  };

  useEffect(() => {
    const ref = setTimeout(() => setSaved(false), 1000);
    return () => clearTimeout(ref);
  }, [saved]);

  return (
    <form className="form-widget" onSubmit={onSubmit}>
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input className="form-control" id="email" type="text" value={session?.user?.email} disabled />
      </div>

      <div className="form-group">
        <label htmlFor="username">Name</label>
        <input className="form-control" id="username" type="text" value={user.username} onChange={(e) => setUsername(e.target.value)} />
      </div>

      <div className="form-group">
        <label htmlFor="password">PASSWORD</label>
        <input className="form-control" id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      </div>

      <button className="btn btn-success" disabled={saved}>
        Save
      </button>

      {saved && <h4>Saved!</h4>}
    </form>
  );
};

const TrelloIntegration = () => {
  const dispatch = useAppDispatch();

  const integrationSettings = useSelector(selectIntegrationSettings);

  if (!integrationSettings) throw Error('Missing integration');

  const trelloUser = useSelector(selectTrelloUser);
  const boards = useSelector(selectBoards);
  const lists = useSelector(selectLists);

  const [apiKey, setApikey] = useState(integrationSettings.api_key || '');
  const [token, setToken] = useState(integrationSettings.token || '');
  const [selectedBoard, setSelectedBoard] = useState(integrationSettings.selected_board || '');
  const [selectedLists, setSelectedLists] = useState(integrationSettings.selected_lunch_lists || []);

  const [saved, setSaved] = useState(false);

  const { hash } = useLocation();

  useEffect(() => {
    if (hash) setToken(hash.replace('#token=', ''));
  }, [hash]);

  useEffect(() => {
    if (trelloUser) {
      dispatch(
        getBoardsAsync({
          key: apiKey,
          token,
          id: trelloUser.id,
        })
      );
    }
  }, [dispatch, apiKey, token, trelloUser]);

  useEffect(() => {
    if (selectedBoard) {
      dispatch(
        getListsAsync({
          key: apiKey,
          token,
          id: selectedBoard,
        })
      );
    }
  }, [dispatch, apiKey, token, selectedBoard]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const newValues: TrelloSettingsInterface = {
      id: integrationSettings.id,
      api_key: apiKey,
      token: token,
      selected_board: selectedBoard,
      selected_lunch_lists: selectedLists,
    };
    const result = await updateIntegration(newValues);
    setSaved(result);
  };

  useEffect(() => {
    const ref = setTimeout(() => setSaved(false), 1000);
    return () => clearTimeout(ref);
  }, [saved]);

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <label htmlFor="apiKey">API Key</label>
        <input className="form-control" type="text" id="apiKey" value={apiKey} onChange={(e) => setApikey(e.target.value)} />
      </div>

      {token ? (
        <div className="form-group">
          <label htmlFor="token">Token</label>
          <input className="form-control" id="token" type="text" value={token} disabled />
        </div>
      ) : (
        <Link to={{ pathname: trelloApi.getOauthUrl() }} target="_blank">
          Sign in with Trello
        </Link>
      )}

      {boards && (
        <div className="form-group">
          <label htmlFor="selectBoard">Select Board</label>
          <select className="form-control" id="selectBoard" value={selectedBoard} onChange={(e) => setSelectedBoard(e.target.value)}>
            {boards.map((board) => (
              <option key={board.id} value={board.id}>
                {board.name}
              </option>
            ))}
          </select>
        </div>
      )}

      {lists && (
        <div className="form-group">
          <label htmlFor="lists">Select which lists you want Recepis from</label>
          <select
            multiple
            className="form-control"
            id="lists"
            value={selectedLists}
            style={{ height: '400px' }}
            onChange={(e) => setSelectedLists(Array.from(e.target.selectedOptions, (option) => option.value))}
          >
            {lists.map((list) => (
              <option key={list.id} value={list.id}>
                {list.name}
              </option>
            ))}
          </select>
        </div>
      )}

      <button className="btn btn-success" disabled={saved}>
        Save
      </button>

      {saved && <h4>Saved!</h4>}
    </form>
  );
};

export default ProfilePage;
