import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from '../reducers/authSlice';
import integrationReducer from '../reducers/integrationSlice';
import recipePickerReducer from '../../recipe-trello/slices/recipePickerSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    integration: integrationReducer,
    recipePicker: recipePickerReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
