import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { matchNameOrSynonyms } from 'src/modules/common/utils/matchers';
import { BaseTrelloEntity } from 'src/modules/recipe-trello/types/_BaseTrelloEntity';
import unitsApi from 'src/modules/units/api/units.api';

import { ingredientsApi, itemsApi, recipesApi } from '../api/recipe.api';
import { trelloApi } from '../api/trelloApi';
import { Ingredient } from '../types/ingredient';
import { Item } from '../types/item';
import { Recipe } from '../types/recipe';
import { Unit } from '../types/unit';

export const useAutoCreateTrelloRecipeIngredients = (trelloId) => {
  const queryClient = useQueryClient();

  const checklistQuery = useQuery(['card-checklist', trelloId], () => trelloApi.getChecklists(trelloId, queryClient));
  const recipeQuery = useQuery(['recipe', trelloId], () => recipesApi.getByTrelloId(trelloId));
  const unitsQuery = useQuery<Unit[], any>(unitsApi.tableKey, () => unitsApi.getAll());
  const itemsQuery = useQuery<Item[], any>(itemsApi.tableKey, () => itemsApi.getAll());

  const [ingredients, setIngredients] = useState<Ingredient[]>([]);

  useEffect(() => {
    const gang = [checklistQuery, recipeQuery, unitsQuery, itemsQuery];
    if (gang.every((query) => query.status === 'success' && !!query.data)) {
      const recipe = recipeQuery.data as Recipe;

      const hasIngredients = recipe.ingredients?.length;
      if (hasIngredients) {
        setIngredients(recipe.ingredients as Ingredient[]);
        return;
      }

      const checkItems = (checklistQuery.data as BaseTrelloEntity).checkItems;
      const allUnits = unitsQuery.data as Unit[];
      const allItems = itemsQuery.data as Item[];

      if (checkItems) {
        Promise.all(
          checkItems.map((checkItem) => {
            const parsed = parser(checkItem.name, allItems, allUnits);
            return ingredientsApi.upsert({ recipe_id: recipe.id, name: checkItem.name, trello_id: checkItem.id, ...parsed });
          })
        ).then((updatedIngredients) => {
          queryClient.setQueryData(['recipe', trelloId], { ...recipe, ingredients: updatedIngredients });
        });
      }
    }
  }, [checklistQuery, recipeQuery, unitsQuery, itemsQuery]);

  return {
    ingredients,
  };
};

export default useAutoCreateTrelloRecipeIngredients;

function parser(str: string, allItems: Item[], allUnits: Unit[]) {
  const parts = str.split(',').map((p) => p.trim());
  const [_item, _quantity, _unit] = parts;

  const response: { item_id?: string; unit_id?: string; quantity?: string } = {
    item_id: undefined,
    unit_id: undefined,
    quantity: undefined,
  };

  if (_item) {
    const foundItem = allItems?.find(matchNameOrSynonyms(_item));
    if (foundItem) response.item_id = foundItem.id;
  }

  if (_quantity) {
    response.quantity = _quantity;
  }

  if (_unit && allUnits) {
    const foundUnit = allUnits?.find(matchNameOrSynonyms(_unit));
    if (foundUnit) response.unit_id = foundUnit.id;
  }

  return response;
}
