import AuthButton from 'src/modules/common/components/header/AuthButton';

const NavbarHeader = ({ mobileMenu, setMobileMenu }) => (
  <div className="row navbarHeader">
    <div className="col">
      <nav className="navbar navbar-light bg-light fixed-top">
        <a className="navbar-brand" href="/">
          SallaMolly
        </a>

        <div>
          <AuthButton />
        </div>
        <button className="navbar-toggler d-block d-md-none" type="button" onClick={() => setMobileMenu(!mobileMenu)}>
          <span className="navbar-toggler-icon"></span>
        </button>
      </nav>
    </div>
  </div>
);

export default NavbarHeader;
