import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/modules/common/app/hooks';
import { reset, selectStep } from 'src/modules/recipe-trello/slices/recipePickerSlice';
import Step1 from 'src/modules/recipe-trello/components/pick-recipe/Step1';
import Step2 from 'src/modules/recipe-trello/components/pick-recipe/Step2';

const RecipePickerPage = () => {
  const dispatch = useAppDispatch();

  const step = useSelector(selectStep);
  let CurrentStepComponent;
  if (step === 1) CurrentStepComponent = Step1;
  if (step === 2) CurrentStepComponent = Step2;

  return (
    <div>
      <button type="button" className="btn btn-primary" onClick={() => dispatch(reset())}>
        Reset
      </button>
      <hr />

      <CurrentStepComponent />
    </div>
  );
};

export default RecipePickerPage;
