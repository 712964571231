import { useState, useEffect } from 'react';
import useSavingState from 'src/modules/common/hooks/useSavingState';

import unitsApi from '../api/units.api';

import { HiCheck, HiPlus } from 'react-icons/hi';
import { Unit as UnitType } from 'src/modules/trello/types/unit';
import { Unit } from './Unit';
import ProgressButton from 'src/modules/common/components/button/ProgressButton';

const UnitsPage = () => {
  const [units, setUnits] = useState<UnitType[]>([]);
  const [newUnit, setNewUnit] = useState('');
  const { saveState, setSaveState, saveText } = useSavingState();

  useEffect(() => {
    unitsApi.getAll().then((d) => setUnits(d));
  }, []);

  const update = (idx, updatedUnit: UnitType) => {
    const copy = [...units];
    copy[idx] = updatedUnit;
    copy[idx]['_markForUpdate'] = true;

    setUnits(copy);
  };

  const onSubmit = (e, complete) => {
    setSaveState('SAVING');

    const itemsToUpdate = units.filter((item) => item['_markForUpdate']);

    Promise.all(
      itemsToUpdate.map((item) => {
        delete item['_markForUpdate'];
        return unitsApi.upsert(item);
      })
    ).finally(() => {
      setSaveState('SAVED');
      complete();
    });
  };

  const createNew = () => {
    setSaveState('SAVING');

    unitsApi.upsert({ name: newUnit }).then((newUnit) => {
      setNewUnit('');
      setUnits(units.concat(newUnit));
      setSaveState('SAVED');
    });
  };

  const destroy = (id: string, unitsIdx: number) => {
    unitsApi.destroy(id).then(() => {
      const copy = [...units];
      copy.splice(unitsIdx, 1);
      setUnits(copy);
    });
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="input-group mb-3">
          <input
            className="form-control"
            type="text"
            placeholder="Enter a unit..."
            value={newUnit}
            onChange={(e) => setNewUnit(e.target.value)}
          />

          <div className="input-group-append">
            <button className="btn btn-primary" type="button" onClick={() => createNew()}>
              <HiPlus /> Create
            </button>
          </div>
        </div>

        {units.map((unit, unitIdx) => (
          <Unit key={unitIdx} unitIdx={unitIdx} unit={unit} onDestroy={destroy} onUpdate={update} />
        ))}

        <ProgressButton onClick={onSubmit}></ProgressButton>
      </div>
    </div>
  );
};

export default UnitsPage;
