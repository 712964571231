import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectTrelloKeyAndToken } from 'src/modules/common/reducers/integrationSlice';
import { getTrelloProfileAsync } from 'src/modules/recipe-trello/api/trelloThunks';
import { useAppDispatch } from 'src/modules/common/app/hooks';
import { Trello } from 'src/modules/trello/api/trelloApi';

const useTrelloInitialization = () => {
  const dispatch = useAppDispatch();
  const { api_key, token } = useSelector(selectTrelloKeyAndToken);

  useEffect(() => {
    if (api_key && token) {
      const api = Trello.getInstance();
      api.setApiKey(api_key);
      api.setToken(token);

      dispatch(getTrelloProfileAsync());
    }
  }, [dispatch, api_key, token]);
};

export default useTrelloInitialization;
