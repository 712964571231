import supabaseApi from 'src/modules/common/api/supabase.crud.api';
import { Item } from 'src/modules/trello/types/item';

const tableKey = 'items';
const select = `
id,
name,
synonyms,
section(
  id,
  name,
  order
)
`;

export const itemsApi = supabaseApi<Item>(tableKey, select);
