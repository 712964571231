import { IconType } from 'react-icons/lib';

import { HiOutlineUser, HiMenuAlt1, HiScale, HiLibrary } from 'react-icons/hi';

import LoginPage from 'src/modules/profile/components/LoginPage';
import ProfilePage from 'src/modules/profile/components/ProfilePage';
import RecipePickerPage from 'src/modules/recipe-trello/components/pick-recipe/RecipePickerPage';
import UpdateTrelloPage from 'src/modules/trello/components/updateTrelloRecipe/UpdateTrelloPage';
import TestPage from 'src/modules/common/components/TestPage';
import UnitsPage from 'src/modules/units/components/UnitsPage';
import SectionsPage from 'src/modules/sections/components/SectionsPage';
import { AuthGuard } from 'src/modules/common/types/guard';
import { ItemsPage } from 'src/modules/items/components/ItemsPage';

interface RouteInterface {
  to: string;
  Component: () => JSX.Element;
  title?: string;
  Icon?: IconType;
  guards?: AuthGuard[];
  hideFromNav?: boolean;
}

export const navRoutes: RouteInterface[] = [
  {
    to: '/profile',
    title: 'Profile',
    guards: [AuthGuard.LOGIN],
    Icon: HiOutlineUser,
    Component: ProfilePage,
  },
  {
    to: '/recipe',
    title: 'Pick Recipe',
    guards: [AuthGuard.LOGIN, AuthGuard.TRELLO],
    Icon: HiMenuAlt1,
    Component: RecipePickerPage,
  },
  {
    to: '/units',
    title: 'Units',
    guards: [AuthGuard.LOGIN],
    Icon: HiScale,
    Component: UnitsPage,
  },
  {
    to: '/sections',
    title: 'Sections',
    guards: [AuthGuard.LOGIN],
    Icon: HiLibrary,
    Component: SectionsPage,
  },
  {
    to: '/items',
    title: 'Shopping Items',
    guards: [AuthGuard.LOGIN],
    Icon: HiLibrary,
    Component: ItemsPage,
  },
];

export const routingRoutes: RouteInterface[] = [
  {
    to: '/trello/update/:trelloId',
    title: 'Update In Trello',
    guards: [AuthGuard.LOGIN, AuthGuard.TRELLO],
    hideFromNav: true,
    Component: UpdateTrelloPage,
  },
  {
    to: '/login',
    hideFromNav: true,
    Component: LoginPage,
  },
  {
    to: '/test',
    hideFromNav: true,
    Component: TestPage,
  },
];

export const routes: RouteInterface[] = navRoutes.concat(routingRoutes);
