type HasName = { name: string; synonyms?: string[] };

export const matchNameOrSynonyms = <T extends HasName>(value: string) => {
  return (item: T) => {
    const val = value.toLowerCase();
    const itemName = item.name.toLowerCase();
    const matches = val.startsWith(itemName) || itemName.toLowerCase().startsWith(val);
    if (matches) return true;

    const synonyms = item.synonyms || [];
    return synonyms.some((syn) => syn.toLowerCase().startsWith(val) || val.startsWith(syn.toLowerCase()));
  };
};
