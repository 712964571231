import { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { trelloApi } from 'src/modules/trello/api/trelloApi';
import { selectIntegrationSettings } from 'src/modules/common/reducers/integrationSlice';
import { selectSelectedRecipes } from 'src/modules/recipe-trello/slices/recipePickerSlice';

const Step2 = () => {
  const selectedRecipes = useSelector(selectSelectedRecipes);
  const integrationSettings = useSelector(selectIntegrationSettings);

  const [checklists, setChecklists] = useState<any[]>([]);

  useEffect(() => {
    const getAllChecklists = async (idList: string[]) => {
      const _checklists = await Promise.all(idList.map((checklistId) => trelloApi.getChecklistById(checklistId)));

      setChecklists(_checklists);
    };

    const idList = selectedRecipes.map((r) => r.idChecklists).flat();
    getAllChecklists(idList);
  }, [integrationSettings, selectedRecipes]);

  return (
    <div>
      <h2>Step 2</h2>

      {checklists.map((checklist, cIdx) => (
        <Fragment key={cIdx}>
          <strong>{checklist.name}</strong>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {checklist.checkItems.map((item, iIdx) => (
              <li key={iIdx}>
                <div className="btn-group btn-group-xs" role="group" aria-label="Extra-small button group">
                  <button type="button" className="btn btn-default">
                    Veggies
                  </button>
                  <button type="button" className="btn btn-default">
                    Isle Section
                  </button>
                  <button type="button" className="btn btn-default">
                    Frozen Section
                  </button>
                </div>
                <span style={{ marginLeft: '1rem' }}>{item.name}</span>
              </li>
            ))}
          </ul>
        </Fragment>
      ))}
    </div>
  );
};

export default Step2;
