import { BaseTrelloEntity } from 'src/modules/recipe-trello/types/_BaseTrelloEntity';
import { Recipe } from '../types/recipe';

export const getLatestVersion = (trelloVersion: unknown, recipeVersion: string | undefined) => {
  let trelloVersionNum = Number(trelloVersion);
  let recipeVersionNum = Number(recipeVersion);

  if (!Number.isFinite(trelloVersionNum)) trelloVersionNum = -1;
  if (!Number.isFinite(recipeVersionNum)) recipeVersionNum = -1;

  return Math.max(trelloVersionNum, recipeVersionNum);
};

export const latestVersion = process.env.REACT_APP_TRELLO_LATEST_VERSION;

export const versionFromTrello = (card: BaseTrelloEntity | undefined): string | undefined => {
  return card?.customFieldItems && card.customFieldItems[0]?.value?.number;
};

type IsLatestVersionProps = {
  trelloCard?: BaseTrelloEntity | undefined;
  recipe?: Recipe | null;
};
export const isLatestVersion = ({ trelloCard, recipe }: IsLatestVersionProps) => {
  if (trelloCard) {
    const _versionFromTrello = versionFromTrello(trelloCard);
    return latestVersion && _versionFromTrello && _versionFromTrello >= latestVersion;
  }

  if (recipe) {
    return latestVersion && recipe.version && recipe.version >= latestVersion;
  }

  return false;
};
