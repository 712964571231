import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useState } from 'react';

import { routes } from 'src/modules/common/app/routes';
import { useAppSelector } from 'src/modules/common/app/hooks';
import { selectAuthState } from 'src/modules/common/reducers/authSlice';
import useTrelloInitialization from 'src/modules/common/hooks/useTrelloInitialization';
import useAuthStateChange from 'src/modules/common/hooks/useAuthStateChange';
import NavbarHeader from 'src/modules/common/components/header/NavbarHeader';
import Sidebar from 'src/modules/common/components/sidebar/Sidebar';
import { useLoginGuard, useTrelloTokenGuard } from 'src/modules/common/api/authGuards';
import { AuthGuard } from 'src/modules/common/types/guard';

export default function App() {
  useAuthStateChange();
  useTrelloInitialization();

  const [mobileMenu, setMobileMenu] = useState(false);

  return (
    <Router>
      <div className="container-fluid pb-5">
        <NavbarHeader mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} />
        <Page mobileMenu={mobileMenu} setMobileMenu={setMobileMenu}>
          <Switch>
            {routes.map((route, routeIdx) => (
              <RouteHandler key={routeIdx} guards={route.guards} component={route.Component} path={route.to} />
            ))}
          </Switch>
        </Page>
      </div>
    </Router>
  );
}

const RouteHandler = (props) => {
  const { guards = [] } = props;

  const loginGuard = useLoginGuard();
  const trelloGuard = useTrelloTokenGuard();

  const authState = useAppSelector(selectAuthState);

  if (authState.loading || !authState.complete) return <div>loading...</div>;

  if (
    guards.every((guardKey: AuthGuard) => {
      if (guardKey === loginGuard.guardKey) return loginGuard.value;
      if (guardKey === trelloGuard.guardKey) return trelloGuard.value;
      return true;
    })
  ) {
    return <Route {...props} />;
  }

  return (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: '/' },
      }}
    />
  );
};

const Page = ({ children, mobileMenu, setMobileMenu }) => {
  return (
    <div className="row">
      <div className="col-md-2 col-12 pb-2">
        <Sidebar mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} />
      </div>
      <div className="col-md-10 col-xs-12">{children}</div>
    </div>
  );
};
