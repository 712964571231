import clsx from 'clsx';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { HiCheck } from 'react-icons/hi';
import { IconType } from 'react-icons/lib';
import omit from 'lodash/omit';

type SaveState = 'IDLE' | 'SAVED' | 'SAVING';

type Props = any & {
  onClick?: (e: any, callback: () => void) => void;
  saveState?: SaveState;
  texts?: [string, string, string];
  icons?: [IconType | null, IconType | null, IconType | null];
  hideTextClass?: boolean;
};

const saveIndexMap = {
  IDLE: 0,
  SAVING: 1,
  SAVED: 2,
};

const ProgressButton = (props: Props) => {
  const { saveState, onClick, texts = ['Save', 'Saving', 'Saved'], icons = [null, Spinner, HiCheck], hideTextOnMobile } = props;
  const passedProps = omit(props, ['saveState', 'texts', 'icons', 'hideTextOnMobile']);

  const [_saveState, setSaveState] = useState<SaveState>('IDLE');

  useEffect(() => {
    if (saveState) {
      setSaveState(saveState);
    }
  }, [saveState]);

  useEffect(() => {
    if (_saveState === 'SAVED') {
      setTimeout(() => {
        setSaveState('IDLE');
      }, 1000);
    }
  }, [_saveState]);

  const _onClick = (e) => {
    setSaveState('SAVING');
    onClick && onClick(e, () => setSaveState('SAVED'));
  };

  const stateIndex = saveIndexMap[_saveState];
  const saveText = texts[stateIndex];
  const Icon = icons[stateIndex];

  return (
    <Button
      {...passedProps}
      disabled={_saveState !== 'IDLE'}
      onClick={(e) => _onClick(e)}
      variant={_saveState === 'SAVED' ? 'success' : 'primary'}
    >
      {hideTextOnMobile ? <span className="d-none d-md-inline">{saveText}</span> : saveText}
      {Icon && <Icon />}
    </Button>
  );
};

export default ProgressButton;

export const Spinner = ({ size } = { size: 'sm' }) => (
  <span className={`spinner-border spinner-border-${size}`} role="status" aria-hidden="true"></span>
);
