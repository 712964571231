import { createAsyncThunk } from '@reduxjs/toolkit';
import { trelloApi } from 'src/modules/trello/api/trelloApi';
import { BaseTrelloEntity } from 'src/modules/recipe-trello/types/_BaseTrelloEntity';
import { TrelloApiParams } from 'src/modules/recipe-trello/types/_TrelloApiParams';

export const getTrelloProfileAsync = createAsyncThunk('integration/getTrelloProfile', async () => {
  return trelloApi.me();
});

export const getBoardsAsync = createAsyncThunk<BaseTrelloEntity[], TrelloApiParams>(
  'trello/getBoards',
  async (settings: TrelloApiParams) => {
    return trelloApi.getBoards(settings.id || '').then((res) => {
      return res.map(({ id, name }) => ({ id, name }));
    });
  }
);

export const getListsAsync = createAsyncThunk<BaseTrelloEntity[], TrelloApiParams>('trello/getLists', async (settings: TrelloApiParams) => {
  return trelloApi.getListsOnBoard(settings.id).then((res) => {
    return res.map(({ id, name }) => ({ id, name }));
  });
});
