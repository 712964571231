import { supabase } from '../api/supabase.api';
import { useEffect } from 'react';
import ProgressButton from './button/ProgressButton';

export default function TestPage() {
  const getData = async () => {
    const { data } = await supabase
      .from('recipes')
      .select(
        `
      *,
      ingredients(
        id,
        quantity,
        unit(
          id,
          name
        ),
        item(
          id,
          name,
          section(
            id,
            name
          )
        )
      )
      `
      )
      .eq('id', 6)
      .single();

    return data;
  };

  // useEffect(() => {
  // getData().then(console.log);
  // }, []);

  const handleOnClick = (e, complete) => {
    setTimeout(() => complete(), 1000);
  };

  return (
    <div>
      <ProgressButton onClick={handleOnClick}></ProgressButton>
    </div>
  );
}
