import { useState, useEffect, useRef } from 'react';
import { Row, Col, Dropdown, InputGroup, Button } from 'react-bootstrap';
import { HiMinus, HiChevronUp, HiChevronDown, HiSave, HiCheck } from 'react-icons/hi';
import { CustomToggle, CustomMenu } from 'src/modules/common/components/dropdown/CustomDropdown';
import { Section } from 'src/modules/sections/types/section';
import { Item as ItemType } from 'src/modules/trello/types/item';
import ProgressButton from '../../common/components/button/ProgressButton';
import { Spinner } from '../../common/components/button/ProgressButton';
import { itemsApi } from '../api/items.api';

type Props = {
  item: ItemType;
  itemIdx: number;
  sections: Section[] | undefined;
  onDestroy: (id: string, idx: number) => void;
  onUpdate: (idx: number, item: ItemType) => void;
};

export const Item = ({ item, itemIdx, sections, onDestroy, onUpdate }: Props) => {
  const selectSectionRef = useRef(null);

  const [currentItem, setCurrentItem] = useState(item);
  const [show, setShow] = useState(false);
  const [synonyms, setSynonyms] = useState(item.synonyms || []);
  const [section, setSection] = useState(item.section);

  useEffect(() => {
    onUpdate(itemIdx, {
      ...currentItem,
      synonyms,
    });
  }, [currentItem, synonyms]);

  const save = (complete) => {
    itemsApi
      .upsert({
        ...currentItem,
        section: section?.id,
      })
      .then((updatedItem) => {
        complete();
      });
  };

  const createSection = (itemName) => {
    // itemsApi.upsert({ name: itemName }).then((newItem) => {
    //   setItem(newItem);
    //   queryClient.setQueryData(itemsApi.tableKey, [newItem, ...(allItems as Item[])]);
    //   selectUnitRef?.current?.click();
    // });
  };

  const variant = (entity) => (entity ? 'outline-success' : 'outline-warning');

  return (
    <>
      <div className="input-group mb-3" key={currentItem.id}>
        <input
          className="form-control"
          type="text"
          value={currentItem.name}
          onChange={(e) => setCurrentItem({ ...currentItem, name: e.target.value })}
        />

        <div className="input-group-append">
          <button className="btn btn-info" type="button" onClick={() => setShow(!show)}>
            {show ? <HiChevronUp /> : <HiChevronDown />} <span className="d-none d-md-inline">Edit</span>
          </button>
          {/* <button className="btn btn-danger" type="button" disabled={!item.id} onClick={() => onDestroy(item.id || '', itemIdx)}>
            <HiMinus /> <span className="d-none d-md-inline">Delete</span>
          </button>

          <ProgressButton onClick={save} disabled={!item.id} icons={[HiSave, Spinner, HiCheck]} hideTextOnMobile></ProgressButton> */}
        </div>
      </div>

      {show && (
        <Row className="mb-4">
          <Col xs="12" md="6">
            <div className="form-group">
              <label>Symonym-list</label>
              <textarea
                className="form-control"
                id="synonyms"
                rows={3}
                value={synonyms.join(',')}
                onChange={(e) => setSynonyms(e.target.value.split(','))}
              ></textarea>
            </div>
          </Col>

          <Col xs="12" md="6">
            <InputGroup className="mb-3">
              <label>Select Section</label>
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle} variant={variant(item)} ref={selectSectionRef}>
                  {section?.name || 'Select Section...'}
                </Dropdown.Toggle>

                <Dropdown.Menu as={CustomMenu} onCreate={(itemName) => createSection(itemName)} align="end">
                  {sections?.map((_section, sectionIdx) => (
                    <Dropdown.Item
                      key={sectionIdx}
                      active={_section?.id === section?.id}
                      eventKey={_section.id}
                      onClick={(e: any) => setSection(_section)}
                    >
                      {_section.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </InputGroup>
          </Col>

          <Col xs="12" md="6">
            <InputGroup className="mb-3">
              <ProgressButton className="w-100" type="button" onClick={(e, complete) => save(complete)}></ProgressButton>
            </InputGroup>
          </Col>
          <Col xs="12" md="6">
            <InputGroup className="mb-3">
              <Button className="w-100" variant="danger" onClick={() => onDestroy(item.id || '', itemIdx)}>
                Delete
              </Button>
            </InputGroup>
          </Col>
        </Row>
      )}
    </>
  );
};
