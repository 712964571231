import { AuthGuard } from '../types/guard';

export const canView = (guards: AuthGuard[] | undefined, loginGuard, trelloGuard) => {
  if (!guards) return true;

  return guards.every((guardKey: AuthGuard) => {
    if (guardKey === loginGuard.guardKey) return loginGuard.value;
    if (guardKey === trelloGuard.guardKey) return trelloGuard.value;
    return true;
  });
};
