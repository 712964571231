import { useHistory, useLocation } from 'react-router-dom';

const useBrowserState = () => {
  const history = useHistory();
  const { state, hash } = useLocation<any>();
  const { from } = state || { from: { pathname: '/' } };

  return {
    history,
    hash,
    from,
  };
};

export default useBrowserState;
