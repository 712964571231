import { useEffect, useState } from 'react';
import { Accordion, Card, Col, Row, useAccordionButton } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ParseIngredient from './ParseIngredient';

import { HiCheck } from 'react-icons/hi';
import useAutoCreateTrelloRecipeIngredients from '../../hooks/useAutoCreateTrelloRecipeIngredients';
import { useQuery, useQueryClient } from 'react-query';
import { recipesApi } from '../../api/recipe.api';
import { Recipe, WithIngredients } from '../../types/recipe';
import { Ingredient } from '../../types/ingredient';
import { Spinner } from '../../../common/components/button/ProgressButton';

const UpdateIngredients = () => {
  let { trelloId } = useParams<any>();

  const queryClient = useQueryClient();

  const recipeQuery = useQuery(['recipe', trelloId], () => recipesApi.getByTrelloId(trelloId));

  const { ingredients } = useAutoCreateTrelloRecipeIngredients(trelloId);

  const onSave = (idx: number, updatedIngredient: Ingredient) => {
    const copy = [...ingredients];
    copy[idx] = updatedIngredient;

    updateIngredients(copy);
  };

  const onDestroy = (idx: number) => {
    const recipe = recipeQuery.data as Recipe & WithIngredients;
    const copy = [...ingredients];
    copy.splice(idx, 1);

    updateIngredients(copy);
  };

  const updateIngredients = (updatedList: Ingredient[]) => {
    const recipe = recipeQuery.data as Recipe & WithIngredients;

    queryClient.setQueryData(['recipe', trelloId], { ...recipe, ingredients: updatedList });
  };

  if (!ingredients)
    return (
      <div>
        <Spinner size="md" />
      </div>
    );

  return (
    <Row className="mt-4">
      <Col xs={12} md={6}>
        <Accordion>
          {ingredients &&
            ingredients.map((ingredient, ingredientIdx) => (
              <Card key={ingredient.id}>
                <Card.Header>
                  <ContextAwareToggle eventKey={ingredient.id} complete={ingredient.unit && ingredient.item}>
                    {ingredient.name}
                  </ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={ingredient.id}>
                  <Card.Body>
                    <ParseIngredient
                      ingredient={ingredient}
                      onSave={(updatedIngredient) => onSave(ingredientIdx, updatedIngredient)}
                      onDestroy={() => onDestroy(ingredientIdx)}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
        </Accordion>
      </Col>
    </Row>
  );
};

export default UpdateIngredients;

function ContextAwareToggle({ children, eventKey, complete }) {
  const decoratedOnClick = useAccordionButton(eventKey);
  // const { activeEventKey } = useContext(AccordionContext);
  // const isCurrentEventKey = activeEventKey === eventKey;

  // useEffect(() => {
  //   console.log(complete);
  // }, [complete]);

  return (
    <Row>
      <Col className="justify-content-between d-flex" onClick={decoratedOnClick}>
        <span>{children}</span>
        <HiCheck size="18" color={complete ? 'green' : 'lightgray'} />
      </Col>
    </Row>
  );
}
