import { Card } from 'react-bootstrap';
import { useRef, useEffect, useState } from 'react';

export const CardImage = ({ images }) => {
  const imgRef = useRef<any>(null);

  const [currentImage, setCurrentImage] = useState(images && images[0].url);

  useEffect(() => {
    let ref;
    const goalWidth = imgRef.current.offsetWidth;

    if (!images) {
      setCurrentImage(`https://via.placeholder.com/${goalWidth}`);
    } else {
      ref = setTimeout(() => {
        images.some((image, idx) => {
          if (idx === images.length - 1 || image.width >= goalWidth) {
            setCurrentImage(image.url);
            return true;
          }

          return false;
        });
      }, 500);
    }

    return () => clearTimeout(ref);
  }, [imgRef.current, images]);

  return <Card.Img variant="top" src={currentImage} ref={imgRef} />;
};
