import supabaseApi from '../../common/api/supabase.crud.api';
import { supabase } from '../../common/api/supabase.api';
import { Recipe } from '../types/recipe';
import { Item } from '../types/item';
import { Ingredient } from '../types/ingredient';

export const itemsApi = supabaseApi<Item>('items');
export const ingredientsApi = supabaseApi<Ingredient>('ingredients');

const recipesTableKey = 'recipes';
export const recipesApi = {
  ...supabaseApi(recipesTableKey),
  getByTrelloId: async (id) => {
    try {
      let { data, error } = await supabase
        .from<Recipe>(recipesTableKey)
        .select(
          `
          *, 
          ingredients(
            *,
            item(
              *
            ),
            unit(
              *
            )
          )
          `
        )
        .eq('trello_id', id)
        .order('id', { foreignTable: 'ingredients' })
        .maybeSingle();

      if (error) {
        throw error;
      }

      if (data) {
        return data;
      }

      return null;
    } catch (error) {
      throw error;
    }
  },
  upsert: async (newValues) => {
    try {
      let { data, error } = newValues.id
        ? await supabase.from<Recipe>(recipesTableKey).upsert(newValues).eq('id', newValues.id).single()
        : await supabase.from<Recipe>(recipesTableKey).upsert(newValues).eq('trello_id', newValues.trello_id).single();

      if (error) {
        throw error;
      }

      if (data) {
        return data;
      }

      throw Error('nope');
    } catch (error) {
      throw error;
    }
  },
};
