import { forwardRef, useEffect, useMemo, useState, Children } from 'react';
import { HiChevronDown, HiPlus } from 'react-icons/hi';
import { matchNameOrSynonyms } from 'src/modules/common/utils/matchers';
import clsx from 'clsx';

import Button from 'react-bootstrap/esm/Button';
import FormControl from 'react-bootstrap/FormControl';

const CustomToggle = forwardRef<any, any>(({ children, onClick, variant }, ref) => (
  <Button
    ref={ref}
    variant={variant}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="w-inherit"
  >
    {children}
    <HiChevronDown />
  </Button>
));

const CustomMenu = forwardRef<any, any>(({ children, style, className, 'aria-labelledby': labeledBy, onCreate, guess = '' }, ref) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(guess);
  }, [guess]);

  const result = useMemo(() => {
    return Children.toArray(children).filter((child: any) => {
      if (!value) return true;

      const children = child.props.children.toLowerCase();
      const val = value.toLowerCase().trim();
      const synonyms = child.props.synonyms || [];

      const matcher = matchNameOrSynonyms(val);

      return matcher({ name: children, synonyms });
    });
  }, [value, children]);

  return (
    <div ref={ref} style={style} className={clsx(className, 'container', 'sticky-dropdown')} aria-labelledby={labeledBy}>
      <div className="input-group mb-3">
        <FormControl autoFocus placeholder="Type to filter..." onChange={(e) => setValue(e.target.value)} value={value} />
        <div className="input-group-append">
          <Button onClick={() => onCreate(value)}>
            <HiPlus />
          </Button>
        </div>
      </div>

      {result.length > 0 ? (
        <ul className="list-unstyled">{result}</ul>
      ) : (
        <div className="p-3">
          <p>Not found</p>
        </div>
      )}
    </div>
  );
});

export { CustomToggle, CustomMenu };
