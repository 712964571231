import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import clsx from 'clsx';
import { selectAuthState } from 'src/modules/common/reducers/authSlice';
import { navRoutes } from 'src/modules/common/app/routes';
import { useTrelloTokenGuard, useLoginGuard } from 'src/modules/common/api/authGuards';
import { canView } from '../../utils/guard.utils';

const Sidebar = ({ mobileMenu, setMobileMenu }) => {
  const { pathname } = useLocation();
  const authState = useSelector(selectAuthState);

  const loginGuard = useLoginGuard();
  const trelloGuard = useTrelloTokenGuard();

  if (authState.loading) return <div>Loading...</div>;

  const visibleRoutes = navRoutes.filter((route) => canView(route.guards, loginGuard, trelloGuard));

  return (
    <ul
      className={clsx(
        'nav nav-pills flex-column d-md-block fixed-top leftMenu__menu',
        !mobileMenu && 'd-none pr-4 pl-4',
        mobileMenu && 'leftMenu__menu--opened'
      )}
    >
      {visibleRoutes.map((route, routeIdx) => (
        <li key={routeIdx} className="nav-item">
          <Link
            className={pathname === route.to ? 'nav-link active' : 'nav-link'}
            to={route.to}
            onClick={() => mobileMenu && setMobileMenu(false)}
          >
            {route.Icon && <route.Icon size={24} className="mr-2" />}
            {route.title}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default Sidebar;
