import supabaseApi from 'src/modules/common/api/supabase.crud.api';
import { Unit } from 'src/modules/trello/types/unit';

const tableKey = 'units';
const select = `
id,
name,
synonyms
`;
const unitsApi = supabaseApi<Unit>(tableKey, select);

export default unitsApi;
