import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { trelloApi } from '../../api/trelloApi';
import { useQuery } from 'react-query';

const UpdateInstructions = () => {
  let { trelloId } = useParams<any>();
  const cardQuery = useQuery(['trello-card', trelloId.toString()], () => trelloApi.getCardById(trelloId));

  return (
    <Row className="mt-4">
      <Col xs={12} md="auto">
        {cardQuery.data?.desc && <ReactMarkdown>{cardQuery.data?.desc}</ReactMarkdown>}
      </Col>
    </Row>
  );
};

export default UpdateInstructions;
