import { createClient, Session } from '@supabase/supabase-js';
import { TrelloSettingsInterface } from 'src/modules/common/types/supabase/trello_settings';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || '';
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY || '';

export const supabase = createClient(supabaseUrl, supabaseAnonKey, { persistSession: true });

export async function updateProfile(session: Session, username: string, password?: string) {
  try {
    const updates = {
      id: session.user?.id,
      username,
    };

    let { error } = await supabase.from('profiles').upsert(updates, {
      returning: 'minimal', // Don't return the value after inserting
    });

    if (error) {
      throw error;
    }

    if (password) {
      updatePassword(session, password);
    }

    return true;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
}

const updatePassword = async (session, password) => {
  try {
    const { error } = await supabase.auth.api.updateUser(session.access_token, { password });
    if (error) throw error;
  } catch (error) {
    throw error;
  }
};

export async function updateIntegration(newValues: TrelloSettingsInterface) {
  try {
    let { error } = await supabase.from<TrelloSettingsInterface>('trello_settings').upsert(newValues, {
      returning: 'minimal', // Don't return the value after inserting
    });

    if (error) {
      throw error;
    }

    return true;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
}
